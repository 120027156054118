.menu {
  align-items: flex-start;
  display: flex;
  height: 287px;
  min-width: 300px;
  position: relative;
}

.menu-1 {
  height: 287px;
}

.ant-menu-dark .ant-menu-item-divider {
  border-color: #ffffff;
}

.menu-1 {
  font-family: "-apple-system", "BlinkMacSystemFont", "Segoe UI", "Roboto",
    "Helvetica Neue", "Arial", "sans-serif", "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol";
}

.ant-menu.ant-menu-dark {
  background: #151718;
}

.ant-menu-vertical .ant-menu-item {
  padding: 0 35px!important;
}

.ant-menu-inline, .ant-menu-vertical, .ant-menu-vertical-left {
    border-right:  1px solid rgba(0, 0, 0, 0.06);
}