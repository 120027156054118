.side-bar {
  align-items: flex-start;
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  width: 300px;
  border-right: 1px solid rgba(0, 0, 0, 0.06);
}

.svb-cleaned-logo {
  padding: 40px 20px 55px 40px;
  width: 300px;
}

.side-bar .menu {

}

.side-bar .svb-cleaned-logo {

}
