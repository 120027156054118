.title {
  height: 29px;
  letter-spacing: 0;
  font-family: Geometria;
}

.services-container {
  padding: 2rem 2rem 1rem 2rem;
  width: 100%;
}

.services {
  align-items: center;
  background-color: var(--white);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 19px 0;
  position: relative;
  width: 900px;
  padding: 2rem;
}

.ant-btn {
  background-color: #E63312;
  border: 2px solid rgba(255,255,255,.17);
  border-radius: 4px;
  color: white;
}

.ant-btn:hover {
  background-color: #ddd;
  border: 2px solid #ddd;
  border-radius: 4px;
  color: #1e1e1e;
}

.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #E63312;
}

.ant-tabs-ink-bar {
   position: absolute;
   background: #E63312!important;
   pointer-events: none;
}

.ant-tabs .ant-tabs-tab:hover {
  color: #E63312;
}

.ant-tabs .ant-tabs-tab.ant-tabs-tab-active {
  border-bottom: 2px solid #E63312 !important;
  z-index: 2
}

.ant-tabs-content-holder {
  text-align: center;
}

.ant-tabs-content {
  margin-top: 20px;
  font-weight: bold;
  font-family: Geometria;
}