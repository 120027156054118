.rw-home {
  align-items: flex-start;
  background-color: #f8f8f8;
  display: flex;
  position: relative;
  width: 100%;
}

.content {
  align-items: flex-start;
  display: flex;
  margin: auto;
  min-width: 960px;
}

.card-container {
  display: block;
  min-height: 95vh;
  position: relative;
  margin: 2rem;
  text-align: -webkit-center;
  text-align: -moz-center;
}

.card-container-1 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-left: 60px;
  min-height: 936px;
  position: relative;
  width: 450px;
}

.ant-layout-sider-below {
  min-width: 300px!important;
}

.ant-layout-sider-collapsed {
  min-width: 0px!important;
}
