.title {
  height: 29px;
  letter-spacing: 0;
  font-family: Geometria;
}

.objects-container {
  padding: 4rem 2rem 1rem 2rem;
  min-width: 700px;
  text-align: left;
}

.objects {
  align-items: center;
  background-color: var(--white);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 19px 0;
  position: relative;
  width: 900px;
  padding: 2rem;
}

.ant-link {
  display: inline!important;
  padding: 6px 10px;
  line-height: 34px!important;
}

.ant-collapse-header-text {
  padding-right: 15px;
}