.vacancies {
  align-items: center;
  background-color: var(--white);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 19px 0;
  position: relative;
  width: 900px;
  padding: 2rem;
  text-align: left;
}

.vacancies-container {
  padding: 4rem 2rem 1rem;
}

.title {
  text-align: center;
  font-family: Geometria;
}

.ant-card-head-title {
  font-size: 20px;
  font-family: Geometria;
}