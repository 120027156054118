.news-main {
  align-items: center;
  background-color: var(--white);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 2rem;
  position: relative;
  width: 900px;
}

.title {
  height: 29px;
  letter-spacing: 0;
  width: 370px;
  font-family: Geometria;
}

.value {
  height: 60px;
  letter-spacing: 0;
  margin-top: 10px;
  text-align: right;
  width: 370px;
}

.news-main-container {
  padding: 4rem 2rem 1rem 2rem;
  text-align: left;
}