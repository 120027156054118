.auth {
  align-items: center;
  background-color: var(--white);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 2rem;
  position: relative;
  width: 900px;
}

.title {
  height: 29px;
  letter-spacing: 0;
  width: 370px;
  text-align: center;
  font-family: Geometria;
}

.value {
  height: 60px;
  letter-spacing: 0;
  margin-top: 10px;
  text-align: right;
  width: 370px;
}

.phone-number-container {
  margin: 4rem 2rem 1rem;   
  text-align: center;
}

.phone-number-input {
  font-size: 24px;
  text-align: center;
}

.phone-number-button {
  margin: 2rem 2rem 1rem 2rem;   
  text-align: center;
}

.code-input-button {
  margin: 2rem 2rem 1rem 2rem;  
  text-align: center;
}

.code-input-text {
    margin-top: 4rem;
}