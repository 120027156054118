.contacts {
  align-items: center;
  background-color: var(--white);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 2rem;
  position: relative;
  width: 900px;
  text-align: left;
  
}

.contacts-container {
  padding: 4rem 2rem 1rem 2rem;
}

.title {
  text-align: center;
  font-family: Geometria;
  width: 400px;
}