.about {
  align-items: center;
  background-color: var(--white);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 19px 0;
  position: relative;
  width: 900px;
  padding: 2rem;
  text-align: left;
}

.about-container {
  padding: 2rem 2rem 1rem 2rem;
}

.title {
  text-align: center;
  font-family: Geometria;
}

.company-cards {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    margin: 50px 0;
}

.company-cards .card-item {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.card-item {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.company-cards .card-item:not(:last-child) {
    margin-right: 30px;
}

.company-cards .card-item__icon {
    width: 90px;
    height: 90px;
    min-width: 90px;
    text-align: center;
    padding-top: 15px;
}
.company-cards .card-item__info {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
}
.card-item__info {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    font-weight: bold;
}
.company-cards .card-item__info h3 {
    font-family: Geometria;
    font-size: 1.5rem;
    margin-bottom: 0px;
}